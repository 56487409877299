import { useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import { VRButton } from 'three/addons/webxr/VRButton.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

THREE.ColorManagement.enabled = true;

export const Panorama = ()=>{
  const holder = useRef();
  const threeObj = useT(holder);

  useEffect(()=>{
    if (!threeObj) return
    appendBox(threeObj);
    console.log('KOK version 2', threeObj)
    animate();
  },[threeObj])


  const animate = () => {
    threeObj.renderer.setAnimationLoop( render );
  }

  const render = () => {
    threeObj.orbit.update();
    threeObj.renderer.render( threeObj.scene, threeObj.camera );
  }


  return (
    <div className="w-full h-full" ref={holder}></div>
  );
}


const useT = (holder)=>{
  const [obj, setObj] = useState(null);
  const initOk = useRef();

  useEffect(()=>{
    if (!holder || initOk.current) return
    let r = {}
    initOk.current = 1;
    r.renderer = new THREE.WebGLRenderer();
    r.renderer.setPixelRatio( window.devicePixelRatio );
    r.renderer.setSize( window.innerWidth, window.innerHeight );
    
    r.renderer.xr.enabled = true;
    r.renderer.xr.setReferenceSpaceType( 'local' );
    r.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
  
    //r.renderer.toneMapping = THREE.ReinhardToneMapping

    holder.current.appendChild( r.renderer.domElement );
    holder.current.appendChild( VRButton.createButton( r.renderer ) );

    r.scene = new THREE.Scene();

    r.camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 1000 );
    r.camera.layers.enable( 1 );
    r.camera.position.z = 0.01;

    // r.light = new THREE.HemisphereLight(0xffffff, 0x000000,3) // soft white light
    // r.scene.add(r.light)    

    r.orbit = new OrbitControls( r.camera, r.renderer.domElement );
    r.orbit.enableZoom = false;
    r.orbit.enablePan = false;
    r.orbit.enableDamping = true;
    r.orbit.rotateSpeed = - 0.25;

    setObj(r);

  },[holder])
  return obj
}




const appendBox = (threeObj) => {
  //^var ld = new THREE.TextureLoader()
  //var textureItems = {}
  //var raycaster = new Raycaster()
  //var screenCenter = new THREE.Vector2(0,0)

  var geometry = new THREE.SphereGeometry(2, 20, 20);
  geometry.scale(-1, 1, 1)

  var tLoader = new THREE.TextureLoader()
  tLoader.setCrossOrigin('')

  const material = new THREE.MeshBasicMaterial({
  //const material = new THREE.MeshLambertMaterial({
    toneMapped:false, 
    side: THREE.DoubleSide ,
    encoding: THREE.sRGBEncoding,
    map:tLoader.load('/assets/forest.jpg',()=>{console.log('LOADDE')}
  )})

  // material.transparent = true
  //material.opacity = 0.2
  //material.color = 0x000000
  //material.toneMapped = false
  
  const mesh = new THREE.Mesh(geometry, material)
  threeObj.scene.add(mesh)  
}